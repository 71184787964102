<template>
  <b-card>

    <!-- Аватарка -->
    <b-media no-body>
      <b-media-aside>
        <b-link>

          <b-img
              v-if="!image"
              ref="previewEl"
              rounded
              :src="avatar"
              height="80"
              width="80"
              fluid
          />
          <b-img
              v-if="image"
              ref="previewEl"
              rounded
              :src="image"
              height="80"
              width="80"
              fluid
          />
        </b-link>
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">

        <!-- Предпросмотр -->
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
        >
          Выбрать файл
        </b-button>
        <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @submit.prevent
            @change="onFileChange"

        />

        <!-- Сброс формы -->
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="resetAvatar"
        >
          Сбросить
        </b-button>
        <b-card-text>Вы можете загрузить JPG, GIF, или PNG</b-card-text>
      </b-media-body>
    </b-media>

    <!-- Форма -->
    <validation-observer v-slot="{invalid}">
      <b-form class="mt-2">
        <b-row>

          <!-- Адрес компании -->
          <b-col sm="6">
            <BlInput name="companyAddress"
                     label="Адрес организации"
                     rules="required"
                     placeholder="Беларусь, г.Минск, ул. Аранская 13, ком. 9-10"
                     input-type="text"
                     v-model="profile.company_address">
            </BlInput>
          </b-col>

          <!-- Email -->
          <b-col sm="6">
            <BlInput name="email"
                     label="Email"
                     rules="required|email"
                     placeholder="username@gmail.com"
                     input-type="text"
                     v-model="profile.email"></BlInput>
          </b-col>

          <!-- Имя пользователя -->
          <b-col sm="6">
            <BlInput name="firstName"
                     label="Имя"
                     rules="required|alpha_spaces"
                     placeholder="Иван"
                     input-type="text"
                     v-model="profile.first_name"></BlInput>
          </b-col>

          <!-- Фамилия пользователя -->
          <b-col sm="6">
            <BlInput name="lastName"
                     label="Фамилия"
                     rules="required|alpha_spaces"
                     placeholder="Иванов"
                     input-type="text"
                     v-model="profile.last_name"></BlInput>
          </b-col>

          <!-- Отчетсво пользователя -->
          <b-col sm="6">
            <BlInput name="middleName"
                     label="Отчество"
                     placeholder="Иванович"
                     input-type="text"
                     v-model="profile.middle_name"></BlInput>
          </b-col>

          <!-- Номер телефона -->
          <b-col sm="6">
            <BlInput name="phone"
                     label="Номер телефона"
                     rules="numeric|required"
                     placeholder="+375 44 766 66 68"
                     input-type="number"
                     v-model="profile.phone"></BlInput>
          </b-col>

          <!-- Должность -->
          <b-col sm="6">
            <BlInput name="position"
                     label="Должность"
                     rules="required|alpha_spaces"
                     placeholder="Менеджер по продажам"
                     input-type="text"
                     v-model="profile.position"></BlInput>
          </b-col>

          <!-- Название компании -->
          <b-col sm="6">
            <BlInput name="nameCompany"
                     label="Наименование организации"
                     rules="required"
                     placeholder="ООО 'Свет'"
                     input-type="text"
                     v-model="profile.company_name"></BlInput>
          </b-col>

          <!-- Уровень цены -->
          <b-col v-if="profile" sm="12">
            <label for="select-price-level">Уровень цены</label>
            <b-form-select id="select-price-level" v-model="profile.price_level"
                           :options="priceLevel.options"></b-form-select>
          </b-col>

          <!-- Менеджер -->
          <b-col sm="12" class="mt-1">
            <label for="manager">Менеджер</label>
            <b-form-select id="manager" v-model="manager.selected" :options="manager.options"></b-form-select>

          </b-col>

          <!-- Кнопки -->
          <b-col cols="6">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="onUpdate"
            >
              Сохранить изменения
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="mt-2"
                type="button"
                @click="getUserById"
            >
              Отмена
            </b-button>
          </b-col>

          <!-- Тоглер -->
          <b-col cols="6" class="d-flex justify-content-center align-items-center">


          </b-col>
          <b-col cols="12" class="mt-2">

            <div style="display: flex; justify-content: space-between; align-items: center">
              <div style="display: flex; align-items: center">
                <b-form-checkbox class="custom-switch-success" name="check-button" v-model="profile.is_active" switch
                                 @change="toogleActivation"/>
                <div v-if="profile.is_active">Клиент активен</div>
                <div v-else>Клиент не активен</div>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="ml-2"
                    :disabled="invalid"
                    @click="changeRole"
                >
                  {{ profile.account_type === 'CLIENT' ? 'Назначить Менеджером' : 'Назначить Клиентом' }}
                </b-button>

              </div>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class=""
                  :disabled="invalid"
                  @click="removeUser"
              >
                Удалить клиента
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import '@/libs/custom-validate'
import {
  BFormSelect,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {ref} from '@vue/composition-api'
import api from "@/api";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import axios from 'axios'
import BlInput from "@/components/controls/BlInput";
import router from "@/router";
import utils from "@/utils";
import {BACKEND_BASE_URL} from "../../../../../constants";

export default {
  components: {
    BFormSelect,
    BlInput,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataLoading: true,

      profile: {
        // price_level: {
        //   id: null,
        // }
      },
      tempProfile: [],
      priceLevel: {
        options: [
          {value: 'OPT1', text: 'Уровень 1'},
          {value: 'OPT2', text: 'Уровень 2'},
          {value: 'OPT3', text: 'Уровень 3'},
          {value: 'OPT4', text: 'Уровень 4'},
          {value: 'RETAIL_NO_NDS', text: 'Розница без НДС'},
          {value: 'RETAIL_NDS', text: 'Розница с НДС'},
        ],
      },
      isActive: false,
      manager: {
        options: [],
        selected: null,
      },

      image: '',
      profileFile: [],
    }
  },
  mounted() {
    this.getUserById()
    this.getOptionsManagers()
    this.loadPriceLevels()
  },
  methods: {
    getUserById() {
      const self = this;
      self.dataLoading = true;
      api.users
          .getUserById(this.getId)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              try {
                self.profile = data.result;
                self.profile.manager.fullName = self.profile.manager.fullName = `${self.profile.manager.last_name} ${self.profile.manager.first_name} ${self.profile.manager.middle_name}`;
                this.tempProfile = this.profile;
                self.dataLoading = false;
                if (self.profile.manager) {
                  self.manager.selected = self.profile.manager.id;
                }
                if (!('price_level' in self.profile) || !('id' in this.profile.price_level)) {
                  this.profile.price_level = {
                    id: null,
                  }
                }
              } catch (e) {
                console.log(e)
              }


            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    getOptionsManagers() {
      const self = this;
      self.dataLoading = true;
      api.users
          .getAllManagers()
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.manager.options = data.result.items
              for (let item of self.manager.options) {
                item.text = `${item.last_name} ${item.first_name} ${item.middle_name}`;
                item.value = item.id;
              }
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });

    },
    resetForm() {
      if (this.$store.state.app.user) {
        this.resetData();
        this.resetAvatar();
      }
    },
    resetData() {

    },
    updateUser() {
      const self = this;
      const id = this.profile.id;
      api.users
          .update(id, {
            email: this.profile.email,
            first_name: this.profile.first_name,
            last_name: this.profile.last_name,
            middle_name: this.profile.middle_name,
            phone: this.profile.phone,
            position: this.profile.position,
            company_name: this.profile.company_name,
            company_address: this.profile.company_address,
            avatar: this.profile.avatar,
            price_level: this.profile.price_level,
            manager: this.manager.selected,
          })
          .then((response) => {
            const data = response.data;

            if (data && data.message) {
              utils.notificate(self, data.message);
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });

    },
    onUpdate() {
      const self = this;

      // Отправка изображения
      console.log(this.profileFile)
      if (self.image) {
        api.files
            .upload(this.profileFile)
            .then((response) => {
              const data = response.data.result;
              console.log(data.path)
              self.profile.avatar = `${BACKEND_BASE_URL}${data.path}`;
              self.updateUser()

            })
            .catch((err) => {
              const data = err.response.data;
              if (data && data.message) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        self.updateUser()
      }

    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    resetAvatar() {
      this.image = null;
    },

    toogleActivation() {
      console.log('toogleActivation')

      const self = this;
      const id = this.profile.id;
      const promiseFunc = this.profile.is_active ? api.users.activateClient : api.users.deactivateClient;
      promiseFunc(id).then(
          (response) => {
            const data = response.data;
            if (data && data.message) {
              utils.notificate(self, data.message);
            }
          }
      )
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    removeUser() {
      const id = this.profile.id;
      const self = this;
      api.users
          .remove(id)
          .then((response) => {

            const data = response.data;
            console.log('Removed')
            console.log(data)
            if (data && data.message) {
              utils.notificate(self, data.message);
            }
            if (!data.error) {
              this.$router.push('/clients')
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });

    },
    loadPriceLevels() {
      api.store.getAllPriceLevels()
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.priceLevel.options = []
              for (let priceLevel of data.result) {
                this.priceLevel.options.push({
                  text: priceLevel.label,
                  value: priceLevel.value,
                })
              }

            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });

    },
    changeRole() {
      const id = this.profile.id;
      const self = this;
      const promise = this.profile.account_type === 'CLIENT' ? api.users.setRoleManager : api.users.setRoleClient;
      const newRole = this.profile.account_type === 'CLIENT' ? 'MANAGER' : 'CLIENT';
      promise(id)
          .then((response) => {

            const data = response.data;
            if (data && data.message) {
              utils.notificate(self, data.message);

            }
            if (!data.error) {
              self.profile.account_type = newRole;
            }

          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });

    }

  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  computed: {
    avatar() {
      if (this.profile.avatar) {
        return this.profile.avatar;
      } else {
        return "/static/noavatar.svg";
      }
    },
    getId() {
      return router.currentRoute.params.id
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
