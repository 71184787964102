<template>
  <b-card>

    <!-- Форма -->
    <validation-observer v-slot="{invalid}">
    <b-form @submit.prevent>
      <b-row>

        <!--Старый пароль-->
        <b-col md="6">
          <b-form-group
              label="Текущий пароль"
              label-for="account-old-password"
          >
            <validation-provider
                #default="{ errors }"
                name="oldPassword"
                rules="required|min:8"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Текущий пароль"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>

        <!-- Новый пароль -->
        <b-col md="6">
          <b-form-group
              label-for="account-new-password"
              label="Новый пароль"
          >
            <validation-provider
                #default="{ errors }"
                name="newPassword"
                rules="required|min:8"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Новый пароль"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Повтор нового пароля -->
        <b-col md="6">
          <b-form-group
              label-for="account-retype-new-password"
              label="Новый пароль еще раз"
          >
            <validation-provider
                #default="{ errors }"
                name="reNewPassword"
                rules="required|confirmed:newPassword|min:8"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Новый пароль еще раз"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

          <!-- Кнопки -->
        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="changePassword()"
              :disabled="invalid"
          >
            Сохранить изменения
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              @click="resetForm"
          >
            Отмена
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import api from '@/api'
import '@/libs/custom-validate'
import Ripple from 'vue-ripple-directive'

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import { BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      invalid: '',
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    isComplete() {
      return (this.passwordValueOld && this.newPasswordValue && this.RetypePassword);
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    resetForm() {
      this.passwordValueOld = '',
      this.newPasswordValue = '',
      this.RetypePassword = ''
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    changePassword() {
      api.auth.change(this.passwordValueOld, this.newPasswordValue)
    }
  },
}
</script>
