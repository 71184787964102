<template>

  <!--Табы-->
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!--Таба "Основные"-->
    <b-tab active>

      <!-- Заголовок -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Основные</span>
      </template>

      <!--Контент-->
      <client-setting-general
        :general-data="generalData"
      />
    </b-tab>

    <!--Таба "Сменить пароль"-->
    <b-tab>

      <!--Заголовок-->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Сменить пароль</span>
      </template>

      <!--Контент-->
      <client-setting-password></client-setting-password>
    </b-tab>
  </b-tabs>
</template>

<script>
import router from '@/router'
import api from '@/api'
import { BTabs, BTab } from 'bootstrap-vue'
import ClientSettingGeneral from "@/components/views/clients/client/ClientSettingGeneral.vue";
import ClientSettingPassword from "@/components/views/clients/client/ClientSettingPassword.vue";
import utils from "@/utils";

export default {
  components: {
    BTabs,
    BTab,
    ClientSettingGeneral,
    ClientSettingPassword,
  },
  data() {
    return {
      generalData: {},
      options: {
      },
      temp: [],
      dataLoading: false,
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {
  },
}
</script>
